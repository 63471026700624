<template>
	<div>
		<HeroSectionTitleOnly :title="store.vuepage.slotprops.heading" :showlinkbutton="false"></HeroSectionTitleOnly>

		<ArticleSection :content="store.vuepage.slotprops.article_content" :scriptinfo="store.vuepage.slotprops.article_content_script" addclasses="extrapadbot extrapadtop"></ArticleSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import FooterSection from "@/components/FooterSection";
import ArticleSection from "@/components/ArticleSection";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly";

export default {
	name: "SimplePage",
	components: {
		HeroSectionTitleOnly,
		ArticleSection,
		FooterSection,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>