<template>
	<div v-if="filteredItems.length > 0" style="margin-bottom: 50px;">
		<p class="dircat-title">{{catitem.title}}</p>
		<DirItem v-for="item in filteredItems" :item="item" :key="item.id"></DirItem>
	</div>
</template>

<script>
import DirItem from "@/components/dirs/DirItem";
import {globalMixin} from "@/mixins"
export default {
	name: "DirCat",
	mixins: [globalMixin],
	components: {DirItem},
	props: {
		catitem: Object,
		chosentownid: Number,
	},
	data: function () {
		return {}
	},
	computed: {
		filteredItems() {
			if(!this.chosentownid || this.chosentownid === 0) return this.catitem.items;
			var its = this.catitem.items.filter(item => item.towns.includes(this.chosentownid));
			return its;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.dircat-title {
	font-weight: bold;
	font-family: 'Montserrat';
	font-size: 22px;
}
</style>