<template>
	<div>
		<AdSection adtype="header" :backgroundclass="bgclass"></AdSection>

		<ArticleTopSection :page="store.vuepage.slotprops" :backgroundclass="bgclass"></ArticleTopSection>

		<EventInfoSection :eventpage="store.vuepage.slotprops"></EventInfoSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content" :scriptinfo="store.vuepage.slotprops.article_content_script" :subheading="store.vuepage.slotprops.subheading"></ArticleSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content_sec" :scriptinfo="store.vuepage.slotprops.article_content_sec_script"></ArticleSection>

		<div v-if="store.vuepage.slotprops.event_link && store.vuepage.slotprops.event_link.length > 0" class="external-link-section">
			<a class="yellow-btn" :href="store.vuepage.slotprops.event_link" target="_blank">Find Out More</a>
		</div>

		<AdSection adtype="body" backgroundclass="bgwhite"></AdSection>

		<ArticleTileSection :tilenewsitems="store.vuepage.slotprops.eventstiles" addclasses="extrapadtop nopadbot"></ArticleTileSection>

		<AdSection adtype="midleader" backgroundclass="bglightgrey"></AdSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bggrey"></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import FooterSection from "@/components/FooterSection";
import ArticleSection from "@/components/ArticleSection";
import ArticleTopSection from "@/components/ArticleTopSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import EventInfoSection from "@/components/EventInfoSection";
export default {
	name: "ArticleEvent",
	components: {
		EventInfoSection,
		ArticleTileSection,
		ArticleTopSection,
		ArticleSection,
		FooterSection,
		ShowTileSection,
		AdSection,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		},
		bgclass() {
			// if(this.store.domain === '3mp') {
			// 	return 'bg3mpeventblue';
			// }
			return 'bglightgrey';
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.covid-note-section {
	width: 1000px;
	padding: 20px 30px;
	margin: 0 auto;
	color: #EA214F;
	font-size: 12px;
	font-family: 'Montserrat';
	font-weight: 600;
}
.external-link-section {
	margin: 20px auto;
	text-align: center;
}
@media screen and (max-width: 1000px) {
	.covid-note-section {
		width: calc(100% - 50px);
	}
}
@media screen and (max-width: 800px) {
	.covid-note-section {
		width: 90%;
	}
}
</style>