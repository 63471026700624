import { reactive } from 'vue'

let isDevServer = false;
if(window.location.origin.indexOf('localhost') > -1) {
	isDevServer = true;
}

export const store = reactive({
	kalert: {
		shown: false,
		text: "",
		type: 'danger',
	},
	urlroot: window.urlroot,
	vuepage: window.vuepage,
	globals: window.vuepage.globals,
	pagetemplate: window.vuepage.pagetemplate,
	pagename: window.vuepage.name,
	pagetitle: window.vuepage.pageTitle,
	pagepath: '',
	loading: false,
	gtagua: window.gtagua,
	domain: window.domain,
	isDevServer: isDevServer,

	sideMenuIsOpen: false,
	fireplaypause: 0,
	adZones: [],
	adZoneContent: [],

	amDomains: ['3wm','3ha','3yb','g1242','3cs','3sh','2ay','2qn','3ne'],
	fmDomains: ['mixx1013','mixx889','coast','trfm','mixx1063','mixx1077','edqn','ewng'],
	mpDomains: ['3mp','2ue','4bh'],
	magicDomains: ['magic1278'],
	metroDomains: ['3mp','2ue','4bh','magic1278'],
	searchVal: '',
})