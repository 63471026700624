<template>
	<div class="tile-show">
		<a v-if="store.loading === false && showitem" :href="showitem.url" class="tile-linker" :data-targpagetpl="showitem.pagetemplate">
			<div v-if="showitem.hero_image && showitem.hero_image.length > 0" class="image-parent" :style="bgimgoverride">
				<div class="image-container">
					<img class="hero-image" :class="{nonEtched : showitem.heroImageIsEtched === false}" :src="showitem.hero_image" :alt="showitem.heading+' image'" />
					<div v-if="showitem.hide_title_overlay !== 1" class="title-holder">
						<p class="show-title">{{showitem.heading}}</p>
					</div>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "TileShow",
	components: {},
	props: {
		showitem: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		bgimgoverride() {
			if(this.showitem.background_override_image && this.showitem.background_override_image.length > 0) {
				return "background-image: url('"+this.showitem.background_override_image+"') !important;";
			}
			return "";
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.tile-show {
	/*width: 380px;*/
	height: 420px;
	flex-basis: 23.5%;
	margin-left: 1%;
	margin-right: 1%;
	box-sizing: border-box;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	margin-bottom: 20px;
}
.tile-show:nth-child(4n-3) { /* item 1, 5, 9 etc */
	margin-left: 0;
}
.tile-show:nth-child(4n) { /* item 4, 8, 12 etc */
	margin-right: 0;
}
.am .tile-show {
	background-image: url('/public-static/backgrounds/home-hero-bg-mob-am.jpg');
}
.fm .tile-show {
	background-image: url('/public-static/backgrounds/home-hero-bg-mob-fm.jpg');
}
.mp .tile-show {
	background-image: url('/public-static/backgrounds/home-hero-bg-mob-mp.jpg');
}
.magic .tile-show {
	background-image: url('/public-static/backgrounds/home-hero-bg-mob-magic.jpg');
}
.tile-linker {
	width: 100%;
	height: 100%;
	display: flex;
	transition: all 0.3s ease-in-out;
}
.tile-linker:hover {
	box-shadow: 0 0 15px 10px rgba(0,0,0,0.1);
}
.image-parent {
	width: 100%;
	height: 100%;
}
.image-container {
	max-height: 100%;
	max-width: 100%;
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	overflow: hidden;
}
.hero-image {
	position: absolute;
	bottom: 0;
	max-height: 100%;
	width: 140%;
	object-fit: contain;
	transition: all 0.3s ease-in-out;
}
.hero-image.nonEtched {
	height: 100%;
	object-fit: cover;
}
.tile-linker:hover .hero-image {
	transform: scale(1.05);
}
.title-holder {
	z-index: 1;
	width: 100%;
	padding: 50px 10% 40px;
	background-image: linear-gradient(180deg, transparent 0, rgba(0,0,0,0.4) 30%, rgba(17,17,17,0.9));
}
.title-holder .show-title {
	padding: 0 10%;
	color: #FFF;
	text-align: center;
	font-size: 35px;
	font-family: 'Montserrat';
	font-weight: 500;
}

@media screen and (max-width: 1800px) {
	.tile-show {
		width: 24%;
		height: 420px;
	}
}
@media screen and (max-width: 1500px) {
	.tile-show {
		width: 23%;
		height: 400px;
	}
	.title-holder .show-title {
		font-size: 26px;
	}
}
@media screen and (max-width: 1250px) {
	.tile-show {
		height: 300px;
	}
	.title-holder .show-title {
		font-size: 22px;
	}
}
@media screen and (max-width: 840px) {
	.tile-show {
		width: 23%;
		height: 250px;
	}
	.title-holder .show-title {
		font-size: 18px;
	}
}
@media screen and (max-width: 840px) {
	.tile-show {
		width: 48%;
		flex-basis: 48%;
		height: 220px;
	}
	.tile-show:nth-child(4n-3) { /* item 1, 5, 9 etc */
		margin-left: 1%;
	}
	.tile-show:nth-child(4n) { /* item 4, 8, 12 etc */
		margin-right: 1%;
	}
	.title-holder .show-title {
		font-size: 18px;
	}
}
@media screen and (max-width: 550px) {
	.tile-show {
		width: 100%;
		flex-basis: 100%;
		margin-left: 0;
		margin-right: 0;
		height: 220px;
		margin-bottom: 30px;
	}
	.title-holder .show-title {
		font-size: 18px;
	}
}
</style>