<template>
	<div class="searchbox">
		<div class="searchbox-holder">
			<input type="text" v-model="searchtext" @keypress.enter="dosearch" />
			<div class="button-append" @click="dosearch">
				<i class="mdi mdi-magnify text-white"></i>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "SearchBox",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			searchtext: '',
		}
	},
	computed: {},
	methods: {
		dosearch() {
			if(this.$router.currentRoute.value.path === '/search-results/') { //start the ajax search
				this.store.searchVal = this.searchtext
			}
			else {
				this.$router.push('/search-results/?q='+this.searchtext);
			}
			this.store.triggerCloseMobileMenu = true
			setTimeout(() => {
				this.searchtext = ''
			}, 1000)
		},
	},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped>
.searchbox {
	padding: 10px 0;
	position: relative;
	width: 100%;
}
.searchbox-holder {
	width: 100%;
}
.searchbox-holder input {
	border-radius: 30px;
	background: #fff;
	border: #fff;
	padding: 10px 20px;
	width: calc(100% - 40px);
}
.button-append {
	color: #000;
	padding: 10px;
	border-radius: 30px;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 8px;
}
.button-append i {
	color: #FFF;
}
</style>