<template>
	<div class="container">
		<div class="form-holder">
			<form @submit="submitForm">
				<div class="form-item-hold">
					<input class="form-input" type="text" v-model="formdata.fname" placeholder="First Name" />
					<p class="invalid-feedback-manual" v-if="fnameState === false">{{fnameInvalidFeedback}}</p>
				</div>

				<div class="form-item-hold">
					<input class="form-input" type="text" v-model="formdata.lname" placeholder="Last Name" />
					<p class="invalid-feedback-manual" v-if="lnameState === false">{{lnameInvalidFeedback}}</p>
				</div>

				<div class="form-item-hold">
					<input class="form-input" type="text" v-model="formdata.email" placeholder="Email Address" />
					<p class="invalid-feedback-manual" v-if="emailState === false">{{emailInvalidFeedback}}</p>
				</div>

				<div class="form-item-hold">
					<input class="form-input" type="text" v-model="formdata.phone" placeholder="Your Phone Number" />
					<p class="invalid-feedback-manual" v-if="phoneState === false">{{phoneInvalidFeedback}}</p>
				</div>

				<div class="form-item-hold">
					<input class="form-input" v-model="formdata.postcode" type="number" placeholder="Postcode" />
					<p class="invalid-feedback-manual" v-if="postcodeState === false">{{postcodInvalidFeedback}}</p>
				</div>

				<div class="form-item-hold" style="margin-top: 30px;">
					<label>
						<input type="checkbox" id="checkbox-1" v-model="formdata.optin" name="checkbox-1" value="1" />
						Yes, I would like to receive information on news, events and competitions from {{showName}}.
					</label>
					<p v-if="optinState === false" class="invalid-feedback-manual">Required</p>
				</div>

				<input type="hidden" id="robots" ref="robots" v-model="robots" />
			</form>

			<div v-if="submissionComplete === false" class="primary-button bgdark textwhite" v-on:click="submitForm" v-bind:class="{'button-is-working':(formworking === true)}">
				<span>Submit</span>
				<WorkingButtonInsert v-if="formworking === true"></WorkingButtonInsert>
			</div>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ShowEDMSignup",
	mixins: [globalMixin],
	components: {
		WorkingButtonInsert
	},
	props: {

	},
	data: function () {
		return {
			store: store,
			checksTriggered: false,
			formworking: false,
			submissionComplete: false,
			formdata: {
				fname: '',
				lname: '',
				email: '',
				phone: '',
				postcode: '',
				optin: '0',
			},
			robots: '',
		}
	},
	computed: {
		showName() {
			if(this.store.vuepage.pageid === 1173) {
				return "The Morning Crew";
			}
			else if(this.store.vuepage.pageid === 1179) {
				return "The Morning Rush";
			}
			return "The Radio Station";
		},
		fnameState() {
			if(this.checksTriggered === false && this.formdata.fname.length < 2) {
				return null;
			}
			else if(this.formdata.fname.length >= 2) {
				return true;
			}
			return false;
		},
		fnameInvalidFeedback() {
			if (this.formdata.fname.length > 0) {
				return 'Enter at least 2 characters.'
			}
			return 'Please enter something.'
		},
		lnameState() {
			if(this.checksTriggered === false && this.formdata.lname.length < 2) {
				return null;
			}
			else if(this.formdata.lname.length >= 2) {
				return true;
			}
			return false;
		},
		lnameInvalidFeedback() {
			if (this.formdata.lname.length > 0) {
				return 'Enter at least 2 characters.'
			}
			return 'Please enter something.'
		},
		emailState() {
			if(this.checksTriggered === false && this.formdata.email.length >= 0 && /.+@.+\..+/.test(this.formdata.email) === false) {
				return null;
			}
			else if(this.formdata.email.length >= 0 && /.+@.+\..+/.test(this.formdata.email)) {
				return true;
			}
			return false;
		},
		emailInvalidFeedback() {
			return 'Please enter a valid email address.'
		},
		phoneState() {
			if(this.checksTriggered === false && this.formdata.phone.length < 6) {
				return null;
			}
			else if(this.formdata.phone.length >= 6) {
				return true;
			}
			return false;
		},
		phoneInvalidFeedback() {
			return 'Please enter a valid phone number.'
		},
		postcodeState() {
			if(this.checksTriggered === false && this.formdata.postcode === '') {
				return null;
			}
			else if(this.formdata.postcode > 1000 && this.formdata.postcode < 9999) return true;
			return false;
		},
		postcodInvalidFeedback() {
			return 'Please enter a valid postcode.';
		},
		optinState() {
			if(this.checksTriggered === false) {
				return null;
			}
			if(this.formdata.optin === true) return true;
			return false;
		},
	},
	methods: {
		submitForm() {
			if(this.formworking === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [this.fnameState, this.lnameState, this.emailState, this.phoneState,
					this.postcodeState, this.optinState];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				if(this.robots.length > 0) return false;
				this.formworking = true; //make button working class
				var form = {};
				form.formdata = this.formdata;
				form.domain = this.store.domain;
				form.pageid = this.store.vuepage.pageid;
				var self = this;
				axios.post(
					"/post/submit-show-edm-signup.php",
					JSON.stringify({data: form})
				).then(function(response) {
					var responsejson = response.data; //response.data is returned info
					self.formworking = false;
					if(responsejson.error === 1) {
						self.showKalert(responsejson);
					}
					else if(responsejson.success === 1) {
						self.showKalert(responsejson);
						self.submissionComplete = true;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>
<style>
input[type="checkbox"].custom-control-input {
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
}
</style>
<style scoped>

</style>