<template>
	<div class="play-widget">
		<div class="button-initial" @click="togglePlay">
			<span class="ltext">Listen</span>
			<span v-if="isplaying"> <i class="mdi mdi-stop"></i> </span>
			<span v-if="isplaying === false"> <i class="mdi mdi-play"></i> </span>
		</div>
		<audio id="audioplayer" ref="audioplayer" :src="streamingLink" type="audio/mpeg"></audio>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "PlayWidget",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			// note: chunks/main.php contains the GFK Sensic data for live 3MP domain
			isplaying: false,
			hasplayedfirsttime: false,
		}
	},
	computed: {
		globals() {
			return this.store.globals;
		},
		streamingLink() {
			return this.globals.domainInfo.streamingLink;
		},
		listnrLink() {
			return this.globals.domainInfo.listnrLink;
		},
		playpauseFromOutside() {
			return this.store.fireplaypause;
		},
	},
	methods: {
		togglePlay() {
			window.open(this.listnrLink, '_blank')
			// var player = this.$refs['audioplayer'];
			// if(!player.paused && player.currentTime) {
			// 	player.pause();
			// 	this.isplaying = false;
			// }
			// else {
			// 	player.play();
			// 	this.isplaying = true;
			// 	this.hasplayedfirsttime = true;
			// }
		},
	},
	watch: {
		playpauseFromOutside() {
			this.togglePlay();
		}
	},
	mounted() {

	}
}
</script>

<style scoped>
#audioplayer {
	display: none;
}
.button-initial {
	text-transform: uppercase;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 600;
	margin: 0 20px;
	background-color: #FFDD00;
	padding: 5px 10px;
	border-radius: 50%;
	cursor: pointer;
}
.mp .button-initial {
	background-color: #97356E;
}
.fm .button-initial {
	color: #2B0233;
}
.am .button-initial {
	color: #002D58;
}
.magic .button-initial {
	background-color: #F06312;
	color: #FFF;
}
.mp .button-initial {
	color: #FFF;
}
.mdi-stop, .mdi-play {
	font-size: 24px;
}
.ltext {
	display: none;
}

@media screen and (max-width: 1350px) {
	.button-initial {
		margin: 0 10px;
	}
}
@media screen and (max-width: 1150px) {
	.button-initial {
		border-radius: 20px;
		padding: 6px 20px;
		display: flex;
	}
	.ltext {
		display: flex;
		align-items: center;
		margin-right: 5px;
	}
	.mdi-stop, .mdi-play {
		font-size: 16px;
	}
}
@media screen and (max-width: 440px) {
	.button-initial {
		padding: 4px 16px;
		border-radius: 10px;
	}
}
@media screen and (max-width: 390px) {
	.button-initial {
		margin-right: 0;
	}
	.ltext {
		display: none;
	}
}
</style>