<template>
	<div>
		<HeroSectionTitleOnly title="Search Results"></HeroSectionTitleOnly>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<div class="search-section bglightgrey">
			<div class="container">
				<div class="search-box-container">
					<input v-model="searchText" type="text" @keypress.enter="dosearch" placeholder="Search" />
					<div class="button-append" @click="dosearch">
						<i class="mdi mdi-magnify text-white"></i>
					</div>
				</div>
			</div>
		</div>

		<div class="search-section-info bglightgrey" v-if="isQuerying">
			<div class="container">
				<p>Searching...please wait</p>
			</div>
		</div>

		<div class="search-section-info bglightgrey" v-if="isQuerying === false && items && items.length === 0">
			<div class="container">
				<p>No search results found</p>
			</div>
		</div>

		<ArticleTileSection v-if="!isQuerying" :tilenewsitems="items"></ArticleTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import axios from "axios";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly";
export default {
	name: "SearchResults",
	components: {
		HeroSectionTitleOnly,
		FooterSection,
		ArticleTileSection,
		AdSection,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			searchText: '',
			isQuerying: true,
			items: [],
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		},
		stextStore() {
			return this.store.searchVal;
		},
	},
	methods: {
		dosearch() {
			if(this.searchText.length === 0) {
				this.isQuerying = false;
				return false;
			}
			this.isQuerying = true;
			var form = {};
			form.searchtext = this.searchText;
			form.domain = this.store.domain;
			var self = this;
			axios.post(
				"/post/search.php",
				JSON.stringify({data: form})
			).then(function(response) {
				self.items = response.data.results;
				self.isQuerying = false;
			}).catch(function(error) {
				console.log(error);
			});
		},
	},
	watch: {
		stextStore() {
			this.searchText = this.store.searchVal;
			setTimeout(function() {
				this.dosearch();
			}.bind(this), 100);
		},
	},
	mounted() {
		let urlparams = this.$route.query; //query for GET vars, params for /:id type vue params
		if(urlparams.q) {
			this.searchText = urlparams.q;
			this.dosearch();
		}
		else {
			this.isQuerying = false;
		}
	}
}
</script>

<style scoped>
.search-section {
	padding: 0 0 20px;
}
.search-box-container {
	margin: 0 auto;
	max-width: 600px;
	display: flex;
}
.search-box-container input {
	width: 100%;
	padding: 10px 10px;
	border: 1px solid #ccc;
}
.button-append {
	background: #888;
	color: #fff;
	padding: 10px;
	border-radius: 0 5px 5px 0;
	cursor: pointer;
}
.search-section-info {
	padding: 40px 0 100px;
}
</style>