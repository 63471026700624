<template>
	<div id="app">

		<AlertTop :obj="store.kalert"></AlertTop>

		<div v-if="firstloadok" id="" :class="skin +' '+ store.domain + ' d'+store.domain">

			<!-- global nav goes here -->
			<NavBar></NavBar>

			<!-- router view handles template view pages content -->
			<router-view></router-view>

			<!-- global footer goes here -->

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from 'axios';
import NavBar from "@/components/NavBar";
import AlertTop from "@/components/AlertTop";

export default {
	name: 'App',
	components: {
		AlertTop,
		NavBar
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			firstloadok: false,


		}
	},
	computed: {
		slotprops() {
			return this.store.vuepage.slotprops;
		},
		settings() {
			return this.store.globals.settings;
		},
		loading() {
			return this.store.loading;
		},
		skin() {
			return this.getSkin()
		},
		pagetemplate() {
			return this.store.pagetemplate;
		},
	},
	methods: {
		removeTrailingSlash(incoming) {
			var lastchar = incoming.slice(-1);
			if(lastchar === "/") {
				incoming = incoming.slice(0,-1);
			}
			return incoming;
		},
		fetchPageJSON(relurl) {
			this.store.loading = true
			this.store.sideMenuIsOpen = false
			this.store.adZones = []
			this.store.adZoneContent = []
			window.scroll({top: 0, left: 0, behavior: 'instant'}); //window.scroll for animation, window.scrollTo for no animation
			var href = this.store.urlroot + relurl;
			var self = this;
			href = this.removeTrailingSlash(href);
			axios.get(href+"/?ajax=1&dom="+self.store.domain).then(function(response) {
				var ret = response.data; //response.data is returned info
				if(ret.error === 1) { console.log(ret); }
				document.title = ret.documentTitle;
				self.setVuepage(ret);
				self.store.pagetitle = ret.pageTitle
				self.store.pagetemplate = ret.pageTemplate
				self.store.pagename = ret.name
				self.store.loading = false
				document.querySelector('head meta[name="description"]').setAttribute('content', ret.metaDescription);
				document.querySelector('head meta[name="keywords"]').setAttribute('content', ret.metaKeywords);
				// window.ga('set','page', href); //old GA method for analytics
				// window.ga('send','pageview');
				if(window.gtag) { //new method if needed
					window.gtag('config', window.gtagua, {'page_path': href}); //gtag GA method for analytics
				}
				self.aceAnalyticsSend(ret.fromCache)
				self.firstloadok = true;
				setTimeout(function() { //call the ads - give Vue time to catch up with render after page content is received
					self.updateAds();
				}.bind(self), 100);
			}).catch(function(error) {
				console.log(error);
				var ret = error.response.data; //error.response.data is returned info
				console.log(ret);
				document.title = ret.documentTitle;
				self.setVuepage(ret);
				self.store.pagetitle = ret.pageTitle
				self.store.pagetemplate = ret.pageTemplate
				self.store.pagename = ret.name
				self.store.loading = false
				self.firstloadok = true;
			});
		},
		updateAds() {
			if(this.store.adZones.length > 0) {
				let zonesneed = encodeURIComponent(this.store.adZones.join("|"));
				let myloc = encodeURIComponent(this.store.urlroot + this.$route.path);
				let getinf = 'https://ace-adserver.com/www/delivery/asyncspc.php?zones=' + zonesneed + '&prefix=revive-0-&target=_blank&loc=' + myloc;
				let self = this;
				axios.get(getinf).then(function (response) {
					let ret = response.data; //response.data is returned info
					self.setAdContent(ret);
				});
			}
		},
		aceAnalyticsSend(fromCache) {
			let screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
			//check if cookie exists
			let aceSessionId = ''
			let cookies = document.cookie.split(";")
			for (let i = 0; i < cookies.length; i++) {
				let cookie = cookies[i].split("=")
				if (cookie[0].trim() == "aceSessionId") {
					aceSessionId = cookie[1]
				}
			}

			let xhttp = new XMLHttpRequest()
			let sob = {
				url: window.location.href,
				getparams: window.location.search,
				fromCache: fromCache,
				screenwidth: screenwidth,
				aceSessionId: aceSessionId
			}
			xhttp.open("POST", "https://analytics.aceradio.com.au/analytics.php", true)
			xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
			xhttp.send(JSON.stringify(sob))
			//handle response
			xhttp.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					let response = JSON.parse(this.responseText)
					if(response.sessionId){
						let cookieExpireTime = "Fri, 31 Dec 9999 23:59:59 GMT"
						if(response.sessionCookieExpire) {
							cookieExpireTime = response.sessionCookieExpire
						}
						else {
							let d = new Date()
							d.setTime(d.getTime() + (30*60*1000))
							cookieExpireTime = d.toUTCString()
						}
						document.cookie = "aceSessionId=" + response.sessionId + "; expires="+cookieExpireTime+"; SameSite=None; Secure; path=/"
					}
				}
			}
		},
	},
	mounted() {
		if(this.firstloadok) {
			this.store.pagetemplate = window.vuepage.pageTemplate
			this.store.pagetitle = window.vuepage.pageTitle
			this.store.pagename = window.vuepage.name
			this.pagepathmutation(this.$route.path);
			this.aceAnalyticsSend(window.vuepage.fromCache);
		}
		//handle clicks in inline content (ie not proper vue-router links)
		window.addEventListener('click', event => {
			let {target} = event; // ensure we use the link, in case the click has been received by a sub-element
			while (target && target.tagName !== 'A') target = target.parentNode
			// handle only links that do not reference external resources
			if (target && target.matches("a:not([href*='://'])") && target.href) {
				//some sanity checks taken from vue-router: https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
				const {altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented} = event;
				if (metaKey || altKey || ctrlKey || shiftKey) return; // don't handle with control keys
				if (defaultPrevented) return; // don't handle when preventDefault called
				if (button !== undefined && button !== 0) return; //don't handle right clicks
				let linkPageTemplate = ""; //try to get the next page template to show skeleton loader whilst we're waiting on ajax
				if (target && target.getAttribute) { // don't handle if `target="_blank"`
					const linkTarget = target.getAttribute('target');
					if (/\b_blank\b/i.test(linkTarget)) return;
					linkPageTemplate = target.getAttribute('data-targpagetpl');
				}
				const url = new URL(target.href); //don't handle same page links/anchors
				const to = url.pathname
				if(window.location.pathname === to) { //fix bug where some page redirects to itself (reloads page)
					event.preventDefault();
					return false;
				}
				if (window.location.pathname !== to && event.preventDefault) {
					event.preventDefault();
					this.store.pagetemplate = linkPageTemplate //will be blank if unknown
					this.$router.push(to);
				}
			}
		});
	},
	watch: {
		$route(to) {
			this.store.loading = true
			this.pagepathmutation(to.path);
			this.fetchPageJSON(this.store.pagepath);
		},
	},
	created() { //used to call ajax on first load if required
		if(window.vuepage.pagetemplate === undefined) {
			//this.fetchPageJSON(this.$route.path);
		}
		else {
			this.firstloadok = true;
		}
	},
};
</script>

<style>
#app {
}
</style>
