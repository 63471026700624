<template>
	<div>
		<HeroSectionTitleOnly :title="title"></HeroSectionTitleOnly>

		<AdSection adtype="header" :backgroundclass="bgclass"></AdSection>

		<ArticleTileSection :tilenewsitems="events1" :backgroundclass="bgclass+' nopadtop nopadbot'"></ArticleTileSection>

<!--		<div v-if="store.domain === '3mp'" class="wing-section bg3mpeventblue">-->
<!--			<div class="container wing-holder">-->
<!--				<div class="left-wing">-->
<!--					<AdSection adtype="side-tower-ad-left"></AdSection>-->
<!--				</div>-->
<!--				<div v-if="store.loading === false" class="tile-holder">-->
<!--					<TileArticle v-for="(st, index) in events1" :newsitem="st" :key="index"></TileArticle>-->
<!--				</div>-->
<!--				<div class="right-wing">-->
<!--					<AdSection adtype="side-tower-ad-right"></AdSection>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->

		<AdSection adtype="body" :backgroundclass="bgclass"></AdSection>

		<ArticleTileSection :tilenewsitems="events2" :backgroundclass="bgclass"></ArticleTileSection>

		<AdSection adtype="midleader" :backgroundclass="bgclass"></AdSection>

		<ArticleTileSection :tilenewsitems="events3" :backgroundclass="bgclass"></ArticleTileSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bgblack"></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly";
// import TileArticle from "@/components/TileArticle";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "AggregateEvents",
	components: {
		// TileArticle,
		HeroSectionTitleOnly,
		FooterSection,
		ArticleTileSection,
		ShowTileSection,
		AdSection,
	},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		title() {
			if(this.store.domain === '3mp') {
				return 'Community Diary';
			}
			return "Local Events";
		},
		bgclass() {
			// if(this.store.domain === '3mp') {
			// 	return 'bg3mpeventblue';
			// }
			return 'bglightgrey';
		},
		vp() {
			return this.store.vuepage;
		},
		events1() {
			var ret = [];
			if(this.vp && this.store.loading === false) {
				for(var i=0; i<4; i++) {
					if(this.vp.slotprops.eventstiles && this.vp.slotprops.eventstiles[i]) {
						ret.push(this.vp.slotprops.eventstiles[i]);
					}
				}
			}
			return ret;
		},
		events2() {
			var ret = [];
			if(this.vp && this.store.loading === false) {
				for(var i=4; i<12; i++) {
					if(this.vp.slotprops.eventstiles && this.vp.slotprops.eventstiles[i]) {
						ret.push(this.vp.slotprops.eventstiles[i]);
					}
				}
			}
			return ret;
		},
		events3() {
			var ret = [];
			if(this.vp && this.store.loading === false) {
				for(var i=12; i<50; i++) {
					if(this.vp.slotprops.eventstiles && this.vp.slotprops.eventstiles[i]) {
						ret.push(this.vp.slotprops.eventstiles[i]);
					}
				}
			}
			return ret;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.d3mp .tile-holder {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-grow: 1;
	padding-left: 40px;
	padding-right: 40px;
}
@media screen and (min-width: 1700px) {
	.d3mp .tile-article {
		max-width: unset;
		width: 24% !important;
	}
}
@media screen and (max-width: 1700px) {
	.left-wing, .right-wing {
		display: none;
	}
	.d3mp .tile-holder {
		padding-left: 0;
		padding-right: 0;
	}
}
</style>