import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
// import {BootstrapVue, IconsPlugin} from 'bootstrap-vue' //TODO remove
// import 'bootstrap/dist/css/bootstrap.css' //TODO remove
// import 'bootstrap-vue/dist/bootstrap-vue.css' //TODO remove

import RouterManager from "@/components/RouterManager";

import '@/style.css'

//routing - PW handles everything by returning the template required, htaccess
const routes = [
	{path: '/*', component: RouterManager},
	{path: "/:catchAll(.*)", component: RouterManager},
]

const router = createRouter({
	hashbang: false,
	routes: routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const app = createApp(App)
app.use(router);
// app.use(BootstrapVue); //TODO remove
// app.use(IconsPlugin); //TODO remove
app.mount('#app')

