<template>
	<div class="hero-section">
		<div v-if="store.loading === false" class="container structure">
			<div class="left-half">
				<h1 class="text-white">{{title}}</h1>
				<p v-if="subheading" class="hero-subheading text-white" v-html="subheading"></p>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "HeroSectionTitleOnly",
	components: {

	},
	props: {
		title: String,
		subheading: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.hero-section {
	height: 250px;
	max-height: 250px;
	background-size: cover;
	background-repeat: no-repeat;
}
.container {
	max-height: 100%;
}
h1 {
	font-family: 'Montserrat';
}
.am .hero-section {
	background-image: url('/public-static/backgrounds/home-hero-bg-am.jpg');
}
.fm .hero-section {
	background-image: url('/public-static/backgrounds/home-hero-bg-fm.jpg');
}
.mp .hero-section {
	background-image: url('/public-static/backgrounds/home-hero-bg-mp.jpg');
}
.magic .hero-section {
	background-image: url('/public-static/backgrounds/home-hero-bg-magic.jpg');
}
.structure {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	height: 100%;
}
.left-half {
	width: 49%;
}

@media screen and (max-width: 959px) {
	.am .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-am.jpg');
	}
	.fm .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-fm.jpg');
	}
	.mp .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-mp.jpg');
	}
	.magic .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-magic.jpg');
	}
}
@media screen and (max-width: 640px) {
	.hero-section {
		height: 200px;
		max-height: 200px;
		position: relative;
	}
	.left-half {
		width: 100%;
		text-align: center;
	}
}
</style>