<template>
	<div>

		<HeroSection v-if="hasHeroBackgroundImage" :page="store.vuepage.slotprops"></HeroSection>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<ArticleTopSection v-if="hasHeroBackgroundImage === false" :page="store.vuepage.slotprops"></ArticleTopSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content" :scriptinfo="store.vuepage.slotprops.article_content_script"></ArticleSection>

		<AdSection adtype="body" backgroundclass="bgwhite"></AdSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content_sec" :scriptinfo="store.vuepage.slotprops.article_content_sec_script"></ArticleSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bggrey"></ShowTileSection>

		<AdSection adtype="midleader" backgroundclass="bggrey"></AdSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import FooterSection from "@/components/FooterSection";
import ArticleSection from "@/components/ArticleSection";
import ArticleTopSection from "@/components/ArticleTopSection";
import HeroSection from "@/components/HeroSection";

export default {
	name: "ArticleCompetition",
	components: {
		HeroSection,
		ArticleTopSection,
		ArticleSection,
		FooterSection,
		ShowTileSection,
		AdSection,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		},
		hasHeroBackgroundImage() {
			if(this.store.vuepage.slotprops.background_override_image && this.store.vuepage.slotprops.background_override_image.length > 0) {
				return true;
			}
			return false;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>
<style>
.scriptholder {
	margin-bottom: 40px;
}
</style>
<style scoped>

</style>