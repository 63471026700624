<template>
	<div class="contact-main">
		<div v-if="store.loading === false" class="article-section">
			<div class="container">
				<div class="article-inside">
					<div v-if="page.slotprops.map_image && page.slotprops.map_image.length > 0" class="map-image-holder">
						<img :src="page.slotprops.map_image" alt="Station broadcast region map" />
					</div>

					<p v-if="page.name === 'contact-us'" class="article-subheading">General Enquiries</p>
					<div v-html="page.slotprops.article_content_sec"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ContactPageMain",
	components: {},
	props: {
		page: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.container {
	padding-top: 30px;
}
.map-image-holder {
	max-width: 100%;
	position: relative;
	margin-bottom: 30px;
}
.map-image-holder img {
	width: 100%;
	object-fit: contain;
}
.article-inside {
	width: 1000px;
	padding: 30px 50px 0;
	margin: 0 auto;
}
.article-subheading {
	font-size: 30px;
}
@media screen and (max-width: 1100px) {
	.article-inside {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}
</style>