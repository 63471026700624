<template>
	<div :class="addclassStr">
		<div v-if="content && content.length > 0 && store.loading === false" class="article-section">
			<div class="container">
				<div class="article-inside">
					<p v-if="subheading && subheading.length > 0" class="article-subheading">{{subheading}}</p>
					<div id="scriptholder"></div>
					<div class="article-content" v-html="content"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import postscribe from 'postscribe'
export default {
	name: "ArticleSection",
	components: {},
	props: {
		content: String,
		subheading: String,
		addclasses: String,
		scriptinfo: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		addclassStr() {
			let str = "";
			if(this.addclasses && this.addclasses.length > 0) str += this.addclasses;
			return str;
		},
	},
	methods: {
		writeScript() {
			setTimeout(function() {
				if(this.scriptinfo && this.scriptinfo.scriptSrc && this.scriptinfo.scriptSrc.length > 0 && this.scriptinfo.targetDivId && this.scriptinfo.targetDivId.length > 0) {
					//'https://form.jotform.com/jsform/212097643443860';
					// eslint-disable-next-line
					postscribe('#'+this.scriptinfo.targetDivId, '<script type="text/javascript" src="'+this.scriptinfo.scriptSrc+'"><\/script>');
				}
			}.bind(this), 250);

		}
	},
	watch: {

	},
	mounted() {
		this.writeScript();
	}
}
</script>
<style>
.article-inside p,
.article-inside li span,
.article-inside p span {
	font-family: 'Roboto';
	font-size: 18px;
	line-height: 1.9;
	margin-bottom: 30px;
}
.article-inside p a,
.article-inside span a,
a.textlinkout {
	text-decoration: underline;
	text-decoration-style: dotted;
	text-underline-offset: 5px;
}
.article-inside p a:hover,
.article-inside span a:hover,
a.textlinkout:hover {
	text-decoration-style: solid;
}
.article-inside iframe,
.article-inside p iframe {
	width: 100%;
	max-width: 100%;
}
.article-content table {
	background-color: #eaeaea;
	border: 2px solid #ffffff;
}

.article-content table td {
	border: 2px solid #ffffff;
}
</style>
<style scoped>
.article-inside {
	width: 1000px;
	padding: 60px 50px 30px;
	margin: 0 auto;
	font-family: 'Roboto';
	font-size: 18px;
	line-height: 1.9;
}
.article-subheading {
	font-size: 30px;
	font-family: 'Montserrat';
	font-weight: 500;
}
.nopadbot {
	padding-bottom: 0;
}
.nopadtop {
	padding-top: 0;
}
.extrapadbot {
	padding-bottom: 80px;
}
.extrapadtop {
	padding-top: 80px;
}

@media screen and (max-width: 1100px) {
	.article-inside {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}
</style>