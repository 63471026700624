<template>
	<div>
		<HeroSection :page="store.vuepage.slotprops.heropage" :showlinkbutton="true"></HeroSection>

		<ListnrSection></ListnrSection>

		<AdSection v-if="!store.metroDomains.includes(store.domain)" adtype="header" backgroundclass="bggrey"></AdSection>
		<RecentlyPlayedSection v-if="store.metroDomains.includes(store.domain)"></RecentlyPlayedSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bgblack"></ShowTileSection>

		<LiveMPSection v-if="store.domain === '3mp'" :page="store.vuepage.slotprops" :showlinkbutton="true"></LiveMPSection>

		<PodcastTileSection v-if="store.vuepage.slotprops.podcasttiles && store.vuepage.slotprops.podcasttiles.length > 0" :tileshows="store.vuepage.slotprops.podcasttiles"></PodcastTileSection>
		<AdSection adtype="body" backgroundclass="bglightgrey"></AdSection>
		<ArticleTileSection :tilenewsitems="store.vuepage.slotprops.newstiles"></ArticleTileSection>
		<AdSection adtype="midleader"></AdSection>
		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import HeroSection from "@/components/HeroSection";
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import PodcastTileSection from "@/components/PodcastTileSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import RecentlyPlayedSection from "@/components/RecentlyPlayedSection";
import LiveMPSection from "@/components/LiveMPSection.vue";
import ListnrSection from "@/components/ListnrSection.vue";
export default {
	name: "HomePage",
	components: {
		ListnrSection,
		LiveMPSection,
		RecentlyPlayedSection,
		FooterSection,
		ArticleTileSection,
		PodcastTileSection,
		ShowTileSection,
		AdSection,
		HeroSection
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>