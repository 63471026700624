<template>
	<div class="article-tile-section" :class="addclassStr">
		<div class="container">
			<div v-if="store.loading === true" class="tile-holder">
				<TileArticle v-for="index in [1,2,3,4]" :key="index"></TileArticle>
			</div>
			<div v-if="store.loading === false" class="tile-holder">
				<TileArticle v-for="(st, index) in tilenewsitems" :newsitem="st" :key="index"></TileArticle>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import TileArticle from "@/components/TileArticle";
export default {
	name: "ArticleTileSection",
	components: {
		TileArticle
	},
	props: {
		tilenewsitems: Array,
		backgroundclass: String,
		addclasses: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		addclassStr() {
			let str = "";
			if(this.backgroundclass) {
				str += this.backgroundclass+" ";
			}
			if(this.addclasses) {
				str += this.addclasses+" ";
			}
			return str;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped>
.article-tile-section {
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #EAEAEA;
	display: flex;
}
.tile-holder {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.nopadbot {
	padding-bottom: 0;
}
.nopadtop {
	padding-top: 0;
}
.extrapadbot {
	padding-bottom: 80px;
}
.extrapadtop {
	padding-top: 80px;
}

@media screen and (max-width: 1050px) {
	.tile-holder {
		justify-content: space-between;
	}
}
</style>