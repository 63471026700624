<template>
	<div>

		<HeroSectionTitleOnly :title="vp.slotprops.title" :showlinkbutton="false"></HeroSectionTitleOnly>

		<div v-if="store.loading === false" class="article-section">
			<div class="container">
				<div class="article-inside">
					<p style="font-weight: bold; font-size: 24px; margin-bottom: 20px;">View/Download PDF File</p>
					<a :href="vp.slotprops.pdf_file_url" target="_blank" style="display: flex; align-items: center;">
						<i class="mdi mdi-file-pdf" style="font-size: 28px;"></i>
						<span style="margin-left: 10px;">{{vp.slotprops.title}}</span>
					</a>
				</div>
			</div>
		</div>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import FooterSection from "@/components/FooterSection";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly";

export default {
	name: "PDFHolder",
	components: {
		HeroSectionTitleOnly,
		FooterSection,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.article-inside {
	width: 1000px;
	min-height: 500px;
	padding: 50px 50px;
	margin: 0 auto;
}
</style>